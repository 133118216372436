<template>
    <div class="weather-card">
        <div id="he-plugin-standard"></div>
    </div>
</template>
<script>
export default {
    name: 'WeatherCard',
    components: {},
    data () {
        return {}
    },
    created () {
    // 和风天气插件调用
        window.WIDGET = {
            'CONFIG': {
                'layout': '2',
                // "width": "440",
                // "height": "440",
                'background': '1',
                'dataColor': 'FFFFFF',
                'key': 'b4331f1d89a64445b3f6782d2b7b369c'
            }
        };
        (function (d) {
            var c = d.createElement('link')
            c.rel = 'stylesheet'
            c.href = 'https://widget.heweather.net/standard/static/css/he-standard.css?v=1.4.0'
            var s = d.createElement('script')
            s.src = 'https://widget.heweather.net/standard/static/js/he-standard.js?v=1.4.0'
            var sn = d.getElementsByTagName('script')[0]
            sn.parentNode.insertBefore(c, sn)
            sn.parentNode.insertBefore(s, sn)
        })(document)
    }
}
</script>
<style lang='less' scoped>
.weather-card {
    box-sizing: border-box;
    height: 100%;

    /deep/ #he-plugin-standard {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0.4rem !important;
        // 隐藏地点和商标
        .wv-lt-refresh,
        .wv-lt-location {
            // .wv-lt-refresh {
            display: none;
        }
        // 当前天气
        .wv-v-v-now {
            .wv-n-h-now-img {
                img {
                    width: 0.78rem;
                    height: 0.78rem;
                    margin: 0.14rem 0.2rem 0.04rem 0;
                }
            }

            .wv-n-h-now-content {
                .wv-n-h-now-tmp {
                    padding-top: 0.02rem;
                    font-size: 0.32rem;
                }
            }

            .wv-n-h-now-rain {
                font-size: 0.2rem;
            }
        }
        // 天气预告
        .wv-v-v-forecast {
            padding: 0.4rem 0.1rem 0.2rem;

            .wv-f-forecast-item {
                font-size: 0.2rem;
            }
        }
    }
}
</style>
